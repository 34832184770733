import axios from 'axios';
import BASE_URL from '../../BASE_URL';

// New Actions for the app

export const SignIn = (ydata, history, handleOpenAlert) => async (dispatch) => {
  await axios
    .post(`${BASE_URL}/checklist/user/login`, { ydata })
    .then(({ data }) => {
      console.log(data);
      if (data.token) {
        dispatch({ type: 'SIGN_IN', payload: data });
        localStorage.setItem('token', data.token);
        history.push('/app');
      }
    })
    .catch((error) => {
      // if (err.response.status === 401) {
      //   handleOpenAlert('yellow');
      // } else {
      //   handleOpenAlert('red');
      // }
      console.log('error');
    });
};

export default SignIn;
